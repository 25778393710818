import {of} from 'rxjs'
import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {finalize, tap} from 'rxjs/operators'
import {Store} from '@ngrx/store'
import * as moment from 'moment'
import {environment} from '../../../environments/environment'
import {AuthResult} from './auth-result'
import {AddUser, ClearUser} from '../../state/user/user.action'
import {AppState} from '../../state/app-state'
import {Login} from '../../models/login'
import {Router} from '@angular/router'
import {HelpersService} from '../helpers/helpers.service'
import {ToastrService} from 'ngx-toastr'
import {StatsService} from '../stats/stats.service'
import {Client} from '../../models/client'
import {PanelService} from '../panel/panel.service'
import {User} from '../../models/user'
import { Callbacks } from '../../models/callbacks'

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    isLogin = false
    roleAs: string[] = ['public']

    constructor(
        private http: HttpClient,
        private store: Store<AppState>,
        private router: Router,
        private helpers: HelpersService,
        private toast: ToastrService,
        private statsService: StatsService,
        private panelService: PanelService
    ) {
    }

    loginHasSessionUser(body: Login) {
        return this.http
            .post<any>(`${environment.api}/auth/session`, body.payload)
            .pipe(
                finalize(() => {
                    if (body.fnFinalized) body.fnFinalized()
                })
            )
            .subscribe((data) => {
                body.fnSuccess(data)
                // this.setSession(data)
            }, body.fnError)
    }

    login(body: Login) {
        return this.http
            .post<any>(`${environment.api}/auth/login`, body.payload)
            .pipe(
                finalize(() => {
                    if (body.fnFinalized) body.fnFinalized()
                })
            )
            .subscribe((data) => {
                const resultData: AuthResult = data.data
                this.setSession(resultData)
                body.fnSuccess()
            }, body.fnError)
    }

    forgot(body: any, callbacks: Callbacks) {
        return this.http
            .post<any>(`${environment.api}/auth/forgot_pass`, body)
            .pipe(
                finalize(() => {
                    if (callbacks.fnFinalized) callbacks.fnFinalized()
                })
            )
            .subscribe(
                (data) => {
                    callbacks.fnSuccess(data)
                },
                (error) => {
                    callbacks.fnError(error)
                }
            )
    }

    newPassword(body: any, callbacks: Callbacks) {
        return this.http
            .post<any>(`${environment.api}/auth/new_password`, body)
            .pipe(
                finalize(() => {
                    if (callbacks.fnFinalized) callbacks.fnFinalized()
                })
            )
            .subscribe(
                (data) => {
                    callbacks.fnSuccess(data)
                },
                (error) => {
                    callbacks.fnError(error)
                }
            )
    }

    refreshToken() {
        const token = this.getRefreshToken()
        return this.http
            .post<any>(`${environment.api}/auth/refresh`, {
                refresh_token: token,
            })
            .pipe(
                tap((data) => {
                    const resultData: AuthResult = data.data
                    this.setSession(resultData)
                })
            )
    }

    logout() {
        return this.http.delete(`${environment.api}/auth/logout`)
    }

    logOutSectionLocal() {
        this.statsService.stats('logout_doctor', 'start')
        this.isLogin = false
        this.roleAs = ['public']
        localStorage.removeItem('roles')
        localStorage.removeItem('token')
        localStorage.removeItem('meMedComplete')
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('expires_at')
        this.setUserState()
        this.statsService.stats('logout_doctor', 'end')
        this.store.dispatch(ClearUser())
        return of({success: this.isLogin, role: this.roleAs})
    }

    private setSession({
                           access_token,
                           refresh_token,
                           expires_at,
                       }: AuthResult) {
        const {user} = this.helpers.decodePayloadJWT(access_token)
        const expiresAt = moment().add(expires_at, 'second')

        this.roleAs = user.roles

        if (
            !this.roleAs.includes('cabin') &&
            !this.roleAs.includes('suitcase')
        ) {
            this.toast.error(
                'Este usuário não tem permissão para acessar a Aplicação de consultas!'
            )
        } else {
            this.isLogin = true
            localStorage.setItem('roles', JSON.stringify(this.roleAs))
            localStorage.setItem('token', access_token)
            localStorage.setItem('refresh_token', refresh_token)
            localStorage.setItem(
                'expires_at',
                JSON.stringify(expiresAt.valueOf())
            )
            this.setUserState()
        }
    }

    setUserState(): void {
        const token = localStorage.getItem('token') || ''
        if (token) {
            let {user} = this.helpers.decodePayloadJWT(token)
            const {clients} = user

            user.client = this.getClient(clients)

            this.store.dispatch(
                AddUser({
                    ...this.helpers.converterSnakeToCamelCase(user),
                })
            )
        } else {
            this.store.dispatch(ClearUser())
        }
    }

    getRole(): string[] {
        const roles = localStorage.getItem('roles')
        this.roleAs = roles ? JSON.parse(roles) : ['public']
        return this.roleAs
    }

    getToken(): string {
        return localStorage.getItem('token') || ''
    }

    getRefreshToken(): string {
        return localStorage.getItem('refresh_token') || ''
    }

    getExpiration() {
        const token = localStorage.getItem('token') || ''
        if (token) {
            const {user} = this.helpers.decodePayloadJWT(token)
            const doctorData = Object.keys(user).find((key) => {
                return key == 'doctor_data'
            })

            if (!doctorData) {
                localStorage.clear()
                return ''
            }
        }

        const expiration = localStorage.getItem('expires_at')
        const expiresAt = expiration ? JSON.parse(expiration) : ''
        return expiresAt ? moment(expiresAt) : ''
    }

    getClient(clients: any): Client {
        const listClients: [string, Client][] = Object.entries(clients)
        listClients[0][1] = this.helpers.converterSnakeToCamelCase(listClients[0][1])

        return {
            id: listClients[0][0],
            name: listClients[0][1].name,
            logo: listClients[0][1].logo,
            memed: listClients[0][1].memed,
            externalConference: listClients[0][1].externalConference,
        }
    }

    getClientLocale(): Client | null {
        const user = this.getUserLocale()
        let client = null
        if(user) {
            const {clients} = user
            client = this.getClient(clients)
        }
        return client
    }

    getUserLocale() {
        const token = localStorage.getItem('token') || ''
        const {user} = this.helpers.decodePayloadJWT(token)
        return user
    }

    isLoggedIn() {
        this.isLogin = moment().isBefore(this.getExpiration())
        return this.isLogin
    }

    redirectAuth(): void {
        const userRole = this.getRole()
        let redirectUrl = ''

        if (userRole.includes('cabin') || userRole.includes('suitcase')) {
            redirectUrl = environment.redirects.roles['cabin']
        } else {
            redirectUrl = environment.redirects.roles['public']
        }

        this.router.navigate([redirectUrl])
    }
}
