const api = 'https://dev-api.haitech.network'

export const environment = {
	api: `${api}/api/v1/main`,
	ws: 'https://dev.haitech.network',
	redirects: {
		roles: {
			cabin: '/status',
			suitcase: '/status',
			public: '/',
		},
	},
    env: 'dev',
	production: false,
}
